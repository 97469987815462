<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <form>
        <md-card>
          <md-card-header
              class="md-card-header-icon"
              :class="getClass(headerColor)"
          >
            <div class="card-icon">
              <template v-if="oper==='add'">
                <md-icon>post_add</md-icon>
              </template>
              <template v-else>
                <md-icon>mode_edit</md-icon>
              </template>

            </div>
            <h4 class="title">
              {{ $t(`label.${oper}`) }}
              <small></small>
            </h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-30" v-for="fl in Object.keys(disaster)" :key="fl">
                <template v-if="fl==='road_id'">
                  <md-field>
                    <label>
                      {{ $t(`disaster.road_id`) }}
                    </label>
                    <md-select
                        v-model="disaster.road_id"
                        @input="onFieldChange('road_id')"
                    >
                      <md-option v-for="road in roads" :key="road.road_id" :value="road.road_id">{{ road.road_id }}
                      </md-option>
                    </md-select>
                  </md-field>
                </template>
                <template v-else>
                  <md-field>
                    <label>
                      {{ $t(`disaster.${fl}`) }}
                    </label>
                    <md-input
                        v-model="disaster[fl]"
                        :type="notNumberFields.includes(fl)?'text':'number'"
                        :data-vv-name="fl"
                        @input="onFieldChange(fl)"
                    ></md-input>
                  </md-field>
                </template>
              </div>
            </div>
            <div class="md-layout-item md-size-100 text-right">
              <md-button
                  class="md-success"
                  native-type="submit"
                  @click.native.prevent="validate"
                  :disabled="nothingSave"
              >
                {{ $t('button.save') }}
              </md-button>
              <md-button class="md-accent" @click.stop.prevent="onCancel">
                {{ $t('button.close') }}
              </md-button>
            </div>

          </md-card-content>
        </md-card>
      </form>
    </div>
  </div>
</template>
<script>

//import {SlideYDownTransition} from 'vue2-transitions'
import Swal from 'sweetalert2'

export default {
  name: 'road-profile-form',
  props: {
    headerColor: {
      type: String,
      default: ''
    },
    oper: String
  },
  components: {
    //SlideYDownTransition
  },
  async created() {
    this.$store.dispatch("LOAD_ROAD_LIST").then(list => {
      this.roads = [...list]
    })
    if (this.oper === "upd") {
      this.disaster = await this.$store.dispatch("LOAD_DISASTER_BY_ID", this.disasterId)
    }
  },
  data() {
    return {
      nothingSave: true,
      disaster: {
        road_id: "",
        direction: "",
        latitude: 0,
        longitude: 0,
        confirmed_risks: 0,
        critical_services: 0,
        alternative_route: 0,
        flooding_confirmed: 0,
        landslide_confirmed: 0,
        erosion_confirmed: 0,
        flooding_history: 0,
        landslide_history: 0,
        erosion_history: 0,
        cut_slope_angle: 0,
        cut_slope_height: 0,
        cut_slope_protection: 0,
        embankment_slope_angle: 0,
        distance_to_road: 0,
        embankment_slope_protection: 0,
        drainage_condition: 0,
        soil_type: 0,
        precipitation: 0,
        temperature: 0,
        import_id: 0,
      },
      roads: [],
      notNumberFields: ["road_id", "key", "direction"],
      disasterId: this.$route.params.id
    }
  },
  methods: {
    onFieldChange() {
      this.nothingSave = false
    },
    onCancel() {
      this.$router.go(-1)
    },
    getClass: function () {
      //return 'md-card-header-' + headerColor + ''
    },
    async validate() {
      const alert = {
        type: 'success',
        text: '',
        footer: ''
      }

      try {
        if (this.oper === "add") {
          await this.$store.dispatch('ADD_DISASTER', this.disaster)
          alert.text = this.$t(`label.disaster_was_added`)
        } else if (this.oper === "upd") {
          await this.$store.dispatch('UPDATE_DISASTER', {
            id: this.disasterId,
            data: {...this.disaster}
          })
          alert.text = this.$t(`label.disaster_was_updated`)
        } else {
          console.log("Unknown operation")
        }

        this.nothingSave = true
        Swal.fire(alert).then(() => {
          //this.touched.category = false
          this.$nextTick(() => this.$validator.reset())
        })
      } catch (err) {
        console.log(err)
        alert.type = 'error'
        if (this.oper === "add") {
          alert.text = this.$t(` label.record_was_not_added`)
        } else if (this.oper === "upd") {
          alert.text = this.$t(`label.record_was_not_updated`)
        }
        //alert.footer = this.$t(err)
        Swal.fire(alert)
      }
    }
  },
  computed: {}
}
</script>
<style>
.md-button + .md-button {
  margin-left: 10px;
}
</style>
